<template>
  <div class="page_box">
    <div class="box_top">
      <Header class="page_hea" />
    </div>
    <div class="box_o">
      <div class="box_left">
        <div
          v-for="item in butData"
          :key="item.id"
          class="but_con"
          :class="{'but_conact':item.active}"
          @click="butOpen(item.id)"
        >
          {{ item.name }}
        </div>
      </div>
      <div class="box_right">
        <div
          v-for="(item,index) in conData[act-1]"
          :key="index"
        >
          <div class="box_title">
            {{ item.title }}
          </div>
          <div class="box_data">
            {{ item.data }}
          </div>
          <div
            v-for="(itemI,ind) in item.imgs"
            :key="ind+'a'"
            class="box_img"
          >
            <van-image
              lazy-load
              :src="itemI.img"
            >
              <template v-slot:loading>
                <img
                  class="loadImg"
                  src="../../../assets/imgs/loading.gif"
                  alt=""
                >
              </template>
            </van-image>
            <div>{{ itemI.name }}</div>
          </div>
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script >
import Header from '@/components/Header.vue';
import Footer from '@/components/Footer.vue';
import img_top from '@/assets/imgs/core/img_top.png';
import core1_1 from '@/assets/imgs/core/core1_1.png';
import core1_2 from '@/assets/imgs/core/core1_2.png';
import core1_3 from '@/assets/imgs/core/core1_3.png';
import core1_4 from '@/assets/imgs/core/core1_4.png';
import core2_1 from '@/assets/imgs/core/core2_1.png';
import core2_2 from '@/assets/imgs/core/core2_2.png';
import core2_3 from '@/assets/imgs/core/core2_3.png';
import core2_4 from '@/assets/imgs/core/core2_4.png';
import core3_1 from '@/assets/imgs/core/core3_1.png';
import core4_1 from '@/assets/imgs/core/core4_1.png';
import core4_2 from '@/assets/imgs/core/core4_2.png';
import core5_1 from '@/assets/imgs/core/core5_1.png';
import core6_1 from '@/assets/imgs/core/core6_1.png';
import core6_2 from '@/assets/imgs/core/core6_2.png';
import core6_3 from '@/assets/imgs/core/core6_3.png';
import core7_1 from '@/assets/imgs/core/core7_1.png';
import core7_2 from '@/assets/imgs/core/core7_2.png';
import core8_1 from '@/assets/imgs/core/core8_1.png';
import core8_2 from '@/assets/imgs/core/core8_2.png';
import core9_1 from '@/assets/imgs/core/core9_1.png';
import core10_1 from '@/assets/imgs/core/core10_1.png';
import core11_1 from '@/assets/imgs/core/core11_1.png';
import core11_2 from '@/assets/imgs/core/core11_2.png';
import core11_3 from '@/assets/imgs/core/core11_3.png';
import core11_4 from '@/assets/imgs/core/core11_4.png';
import core12_1 from '@/assets/imgs/core/core12_1.png';

export default {
  name: 'CorePage',
  components: {
    Header,
    Footer,
  },
  data() {
    return {
      img_top,
      act: 1,
      butData: [
        {
          id: 1,
          name: '自定义功能',
          active: 1,
        },
        {
          id: 2,
          name: '工作流引擎',
          active: 0,
        },
        {
          id: 3,
          name: '即时通讯(IM)',
          active: 0,
        },
        {
          id: 4,
          name: 'SaaS运营',
          active: 0,
        },
        {
          id: 5,
          name: '钉钉微信对接',
          active: 0,
        },
        {
          id: 6,
          name: 'App快速开发',
          active: 0,
        },
        {
          id: 7,
          name: 'App插件库',
          active: 0,
        },
        {
          id: 8,
          name: '统一文件管理',
          active: 0,
        },
        {
          id: 9,
          name: 'BI引擎',
          active: 0,
        },
        {
          id: 10,
          name: '数据导入导出',
          active: 0,
        },
        {
          id: 11,
          name: 'Web打印模块',
          active: 0,
        },
        {
          id: 12,
          name: '多数据源处理',
          active: 0,
        },
      ],
      conData: [
        [
          {
            title: '1.表功能自定义',
            data: 'JEPaaS平台支持oracle，sqlserver、mysql等主流数据库，支持包括树形表、单表、主子表、视图在内的多种表类型，可以将表、字段、主外键、索引统一管理，利用工具快速建表以及实现对表的维护与更新。',
            imgs: [
              {
                id: 0,
                img: core1_1,
                name: '数据结构引擎客户端操作界面',
              },
              {
                id: 1,
                img: core1_2,
                name: '数据结构变更日志操作界面',
              },
              {
                id: 2,
                img: core1_3,
                name: 'E-R展示',
              }],
          },
          {
            title: '2.表结构自定义',
            data: 'JEPaaS平台支持不同用户依据自身需求对门户进行自定义设置。门户引擎提供了包括功能、图表、报表、展板、新闻、自定义插件、HTML插件等在内的多种板块，用户可自行规划、灵活添加、随时调整，实现门户的个性化布局。',
            imgs: [
              {
                id: 0,
                img: core1_4,
                name: '表单设计工具操作界面',
              }],
          },
        ],
        [
          {
            title: '1.便捷流程设计',
            data: 'JEPaaS的工作流引擎采用可视化编辑，提供包括串行、并行、多分支、嵌套等多种形式等流程设计，并支持流程版本控制、消息提醒、审批记录、流程流转事件，帮助开发人员设计出专业的流程功能，提高工作效率。',
            imgs: [{
              id: 0,
              img: core2_1,
              name: 'Web流程编辑器',
            }, {
              id: 0,
              img: core2_2,
              name: '流程入口',
            }, {
              id: 0,
              img: core2_3,
              name: '流程审批',
            }, {
              id: 0,
              img: core2_4,
              name: '流程追踪',
            }],
          },
        ],
        [
          {
            title: '1.即时通讯(IM)',
            data: 'JEPaaS提供稳定高效的即时通讯服务，支持多种类型的信息沟通与用户及社群管理，轻松建立实时的传播矩阵，保证了信息交流的顺畅与安全',
            imgs: [
              {
                id: 0,
                img: core3_1,
                name: 'IM界面',
              }],
          },
        ],
        [
          {
            title: '1.SaaS快速搭建及上架',
            data: '您可使用JEPaaS快速开发自己的SaaS产品，JEPaaS提供了丰富的平台资源可助您快速创建自己的SaaS应用，然后您还能通过平台的商城进行上架授权，JEPaaS并提供运营监控机制。',
            imgs: [
              {
                id: 0,
                img: core4_1,
                name: 'SaaS商城界面',
              }],
          },
          {
            title: '2.SaaS运营管理',
            data: '通过JEPaaS的SaaS运营管理体系，您可以对产品进行灵活管理：自定义产品信息、价格、产品功能范围等；同时，商城提供按照时间、人员的购买方式，并支持在线支付。您可及时了解您的SaaS产品运营状况，直观的数据展示能助您及时调整运营策略。并可以将用户使用该系统的运行数据、用户运维，对指定用户进行定制化扩展。',
            imgs: [{
              id: 0,
              img: core4_2,
              name: '运营监控界面',
            }],
          },
        ],
        [
          {
            title: '1.钉钉微信对接',
            data: 'JEPaaS集成钉钉与企业微信，通过组织架构的同步对接。可实现消息提醒、微信办公、钉钉办公，让您的工作更高效更轻松。',
            imgs: [{
              id: 0,
              img: core5_1,
              name: '钉钉、微信无缝对接',
            }],
          },
        ],
        [
          {
            title: '1.App快速开发',
            data: 'JEPaaS提供在线的App开发工具，使用配置方式可快速开发出APP的多种功能。其中，可进行菜单的快速规划授权，支持功能、插件、图表、报表、链接等多种功能类型，并可通过列表使用自定义HTML快速开发、表单快速配置、查询快速配置的功能开发。',
            imgs: [{
              id: 0,
              img: core6_1,
              name: 'APP功能设计器',
            }, {
              id: 1,
              img: core6_2,
              name: '列表 表单 IM',
            }, {
              id: 2,
              img: core6_3,
              name: '流程 图表 外勤',
            }],
          },
        ],
        [
          {
            title: '1.App插件库',
            data: '',
            imgs: [{
              id: 0,
              img: core7_1,
              name: '功能强大全面的APP插件库',
            }, {
              id: 1,
              img: core7_2,
              name: '插件应用实例',
            }],
          },
        ],
        [
          {
            title: '1.统一文件管理',
            data: '文件库提供网盘功能，可实现文件快速上传管理。该文件库贯穿整个系统，在指定业务选文件可选文件，指定业务文件需要保留也可直接保存到网盘。',
            imgs: [{
              id: 0,
              img: core8_1,
              name: '文件资源管理',
            }, {
              id: 1,
              img: core8_2,
              name: '文件管理架构图',
            }],
          },
        ],
        [
          {
            title: '1.BI引擎',
            data: 'JEPaaS的BI引擎可快速搭建面向全员的数据分析及BI平台，实现业务数据的可视化分析，充分了解并利用数据，辅助决策、提升业务。',
            imgs: [{
              id: 0,
              img: core9_1,
              name: '图形报表案例',
            }],
          },
        ],
        [
          {
            title: '1.数据导入导出',
            data: 'JEPaaS支持Excel的数据导入功能，提供规划模版工具，可解析各种复杂的excel数据内容排版，将用户的excel数据直接导入到系统中。其中，导出主要提供列表的导出、打印格式的导入、也可以自己规划模版来将数据导出。',
            imgs: [{
              id: 0,
              img: core10_1,
              name: 'Excel的数据快速导入导出',
            }],
          },
        ],
        [
          {
            title: '1.Web打印模块',
            data: 'JEPaaS提供打印模版快速规划工具，可将复杂的单据数据指定打印。便捷的打印工具，可实现更高效的办公体验。',
            imgs: [{
              id: 0,
              img: core11_1,
              name: '表格打印配置',
            }, {
              id: 1,
              img: core11_2,
              name: '表单打印配置',
            }, {
              id: 2,
              img: core11_3,
              name: '表格打印案例',
            }, {
              id: 3,
              img: core11_4,
              name: '表单打印案例',
            }],
          },
        ],
        [
          {
            title: '1.多数据源处理',
            data: 'JEPaaS提供可维护指定数据库，加载指定的数据源，引用集成外部系统、外部数据源来完成业务操作，如：在本系统中想查看考勤系统的报表，可直接引用数据源，利用自己jepass的报表工具来完成展示。',
            imgs: [{
              id: 1,
              img: core12_1,
              name: '多数据源稳定高效处理',
            }],
          },
        ],
      ],
    };
  },
  created() {
    const self = this;
    self.act = self.$route.params.id || 1;
    self.butData.map(item => (item.id == (self.$route.params.id || 1) ? item.active = 1 : item.active = 0));
  },
  methods: {
    butOpen(e) {
      const self = this;
      self.act = e;
      self.butData.map(item => (item.id == e ? item.active = 1 : item.active = 0));
    },
  },
};
</script>

<style lang='less' scoped >
.page_box {
  .box_top {
    width: 100%;
    height: 80px;
    background: linear-gradient(
      270deg,
      rgba(252, 103, 81, 1) 0%,
      rgba(246, 71, 77, 1) 100%
    );
  }
  .box_o {
    width: 1200px;
    text-align: center;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    padding: 80px 0 120px 0;
    .box_left {
      width: 200px;
      height: 720px;
      .but_con {
        cursor: pointer;
        height: 60px;
        background: rgba(238, 238, 238, 1);
        box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.15);
        border-bottom: 1px solid #dddddd;
        font-size: 16px;
        font-weight: 400;
        color: rgba(51, 51, 51, 1);
        line-height: 60px;
      }
      .but_conact {
        cursor: pointer;
        height: 60px;
        background: rgba(29, 34, 49, 1);
        box-shadow: 0px 5px 20px 0px rgba(0, 0, 0, 0.2);
        font-size: 16px;
        font-weight: 400;
        color: rgba(255, 255, 255, 1);
        line-height: 60px;
      }
    }
    .box_right {
      width: 950px;
      text-align: left;
      .box_title {
        line-height: 50px;
        font-size: 24px;
        font-weight: bold;
        color: rgba(51, 51, 51, 1);
      }
      .box_data {
        font-size: 16px;
        font-weight: 400;
        color: rgba(102, 102, 102, 1);
        line-height: 24px;
        padding: 10px 0;
      }
      .box_img {
        text-align: center;
        img {
          width: 950px;
          padding: 10px 0;
        }
        .loadImg {
          width: 250px;
          height: 250px;
          margin: 0 auto;
        }
        div {
          font-size: 18px;
          color: #333;
          padding-bottom: 20px;
        }
      }
    }
  }
}
</style>
